.scb_button {
  color: white;
  border: none;
  background-color: #ec0000;
  height: 25px;
  font-size: 15px;
  border-radius: 3px;
  min-width: 100px;
}
.scb_button:disabled {
  background-color: rgb(228, 228, 228);
}
.scb_button_small {
  color: white;
  border: none;
  background-color: #ec0000;
  width: 80px;
  height: 25px;
  box-shadow: 10px 30px;
}
.scb_button_small:disabled {
  background-color: lightgray;
}
.scb_edit_field {
  font-size: 15px;
  border: none;
  border-bottom: solid 1px #ec0000;
  border-radius: 4px;
}
* {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
}
*:focus {
  outline: none;
}
select {
  border: solid 0.5px #c3c1c1;
  border-radius: 3px;
  height: 25px;
  width: 100%;
  font-size: 12px;
}
.validation-error {
  border: 0.5px solid lightcoral !important;
}

html {
  height: 100%;
}
body {
  height: 100%;
  display: flex;
}
body > div {
  flex: 1;
}
.date-piker-field .react-datepicker-wrapper {
  font-size: 12px;
  width: 100%;
  border: 0.5px solid #c3c1c1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 25px;
}
.date-piker-field .col {
}
.date-piker-field input {
  padding-top: 3px;
  padding-left: 5px;
  width: 100%;
  border: none;
}
.date-piker-field input::placeholder {
  font-size: 11px;
  padding-left: 5px;
}
.date-piker-field .prefix {
  height: 25px;
  padding: 0px;
  padding-top: 3px;
  border-top-left-radius: 3px;
  text-align: center;
  display: block;
  border-bottom-left-radius: 3px;
  width: 30px;
  font-size: 12px;
  background-color: #ececec;
  border: 0.5px solid #c3c1c1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.date-piker-field .postfix {
  padding-top: 3px;
  height: 25px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  text-align: center;
  display: block;
  border-bottom-right-radius: 3px;
  width: 30px;
  font-size: 12px;
  background-color: #ececec;
  border: 0.5px solid #c3c1c1;
}
.validation-input-field .small-font {
  font-size: 9px !important;
  padding-top: 3px;
}
.validation-input-field .edit-field-validation {
  font-size: 12px !important;
}
.validation-input-field .prefix {
  height: 25px;
  padding: 0px;
  padding-top: 3px;
  border-top-left-radius: 3px;
  text-align: center;
  display: block;
  border-bottom-left-radius: 3px;
  width: 30px;
  font-size: 12px !important;
  background-color: #ececec;
  border: 0.5px solid #c3c1c1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.validation-input-field .edit-field-with-prefix {
  font-size: 12px !important;
  padding-left: 5px;
  width: 100%;
  height: 25px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid #c3c1c1;
  border-left: none;
}
.validation-input-field .edit-field {
  font-size: 12px !important;
  padding-left: 5px;
  width: 100%;
  height: 25px;
  border-radius: 3px;
  border: 0.5px solid #c3c1c1;
}

.bottom-row {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.alert {
  margin-bottom: 0;
}
.main-container {
  padding-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
}
.dealer-nav-item {
  font-size: 12px;
  max-width: 400px;
  text-align: right;
}

