.preview-panel {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 15px;
  height: 85%;
}
.preview-car-photo {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  border: none;
  object-fit: contain;
}
.preview-dealer-logo {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  border: none;
  object-fit: contain;
}
.preview-title {
  font-size: 16px;
  font-weight: bold;
}
.preview-line {
  font-size: 14px;
}
.info-text {
  font-size: 15px;
  font-weight: bold;
}
