.navigation_bar {
  background-color: white;
  border-bottom: solid 0.5px #ec0000;
  color: #ec0000;
  height: 60px;
}
.navigation_item {
  color: #ec0000;
  background: transparent;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
  height: 45px;
  line-height: 50px;
  font-size: 16px;
}
.nav-item > a:hover {
  color: black;
}

.nav-item-active {
  border-bottom: solid 2px #ec0000;
  font-weight: bold;
}

.navbar-brand {
  padding-top: 0 !important;
}

.scb-logo {
  height: 50px;
  color: white;
  border: 1x solid black;
}
.dealer-search-box {
  color: black;
  border: none !important;
  padding: 0px !important;
  min-height:20px !important;
  border-bottom: solid 0.5px #ec0000 !important;
}
.login-as {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 14px;
}

