@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #ec0000;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.loading-text {
  position: absolute;

  top: 55%;
  padding-left: 30px;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  width: 100%;
  
}
.spinner-container {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.3) !important;
}
