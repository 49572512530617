.financing-field .financing-label {
    color: gray;
    font-size: 11px;
    margin: 0px;
    text-align: right;
    margin-top: auto;
}
.financing-field .col-3 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;

}
.financing-field .financing-select {
    width: 100%;
}
.financing-data-input-column {
    border-right: 0.5px solid #ec0000;
}
.financing-result{
    width: 100%;
    font-size: 14px;
} 
.financing-result .financing-resut-top-field {
    background-color: #ec0000;
    color: white;
    height: 30px;
    width: 100%;
} 
.financing-result .financing-resut-normal-field {
    min-height: 30px;
    width: 100%;
    border-bottom: solid 1px gray;
} 
.financing-result .financing-value {
    text-align: end;
} 