.edit-field-validation {
  font-size: 12px;
  padding-left: 5px;
  width: 100%;
  height:25px;
  border-radius: 3px;
  border: 0.5px solid #c3c1c1;
}
.edit-field-validation-text {
  font-size: 10px;
  color: lightcoral;
}
.image-panel {
}
.image-panel-path {
  color: gray;
  font-size: 10px;
  margin: 0px;
  margin-bottom: 2px;
}
.image-panel-preview {
  max-width: 250px;
  max-height: 150px;
  width: auto;
  height: auto;
  border: solid 1px lightgray;
  object-fit: contain;
}
.image-panel-info-text {
  font-size: 10px;
  color: gray;
}
.image-panel-error-message {
  font-size: 12px;
  font-weight: bold;
  color: red;
}
.upload-button {
  font-size: 12px;
  border: none;
  background-color: #ec0000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 3px;
}
.default-upload-button {

  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}




.label-input {
  color: gray;
  font-size: 10px;
  margin: 0px;
}

.text-area {
  font-size: 12px;
  padding-left: 5px;
  width: 100%;
  border-radius: 3px;
  border: 0.5px solid #c3c1c1;
}

.table-sort-datepicker {
  max-width: 120px;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  height:28px;
  text-align: center;
}
.amount-field {
  font-size: 12px;
  width: 100%;
}
.amount-field :disabled{
  background-color: #e0e0e0;
}

.amount-field .col {
  padding: 0;
}
.amount-field .row{
  padding: 0;
  margin: 0;
}
.amount-field .col-auto {
  padding: 0;
}
.amount-field .myfield-prefix-postfix {
  text-align:right;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  height:25px;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: 0.5px solid #c3c1c1;
  border-bottom: 0.5px solid #c3c1c1;
}
.amount-field .myfield-prefix {
  text-align:right;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  height:25px;
  border-radius: 0;
  border-left: none;
  border-right: 0.5px solid #c3c1c1;
  border-top: 0.5px solid #c3c1c1;
  border-bottom: 0.5px solid #c3c1c1;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.amount-field .myfield-postfix {
  font-size: 12px;
  text-align:right;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  height:25px;
  border-radius: 0;
  border-right: none;
  border-left: 0.5px solid #c3c1c1;
  border-top: 0.5px solid #c3c1c1;
  border-bottom: 0.5px solid #c3c1c1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.amount-field .myfield {
  text-align:right;
  padding-right: 5px;
  padding-left: 5px;
  width: 100%;
  height:25px;
  border-radius: 3px;
  border: 0.5px solid #c3c1c1;
    font-size: 12px;
}

.amount-field .prefix {
  height:25px;
  padding: 0px;
  padding-top: 3px;
    border-top-left-radius: 3px;
    text-align: center;
    display: block;
    border-bottom-left-radius: 3px;
    width: 30px;
    font-size: 12px;
    background-color: #ececec;
    border: 0.5px solid #c3c1c1;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.amount-field .postfix {
  padding-top: 3px;
    height:25px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 3px;
    text-align: center;
    display: block;
    border-bottom-right-radius: 3px;
    width: 30px;
    font-size: 12px;
    background-color: #ececec;
    border: 0.5px solid #c3c1c1;

}