.step-title {
  font-size: 12px;
  border-bottom: solid 1px #ec0000;
  color: gray;
}
.step-title-active {
  height: 25px;
  font-size: 12px;
  border-bottom: solid 2px #ec0000;
  color: white;
  font-weight: bold;
  background-color: #ec0000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.step-title .step-text {
  font-size: 12px;
  color: gray;
  text-align: center;
  padding-top: 4px;
}

.step-title-active .step-text {
  font-size: 12px;
  color: white;
  text-align: center;
  padding-top: 4px;
}
.step-row {
}
