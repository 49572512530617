.edit_offer {
  font-size: 12px;
}
.edit_panel {
  border: none;
  border-left: solid 1px #ec0000;
  min-height: 650px;
}
.customer-data-panel {
  border-bottom: solid 1px #ec0000;
  font-size: 13px;
  padding-bottom: 5px;
}
.contract-data-panel {
  font-size: 13px;
  border-bottom: solid 1px #ec0000;
  padding-bottom: 5px;
}
.info-title {
  font-size: 15px;
  font-weight: bold;
}
.edit_offer_panel {
  min-height: 630px;
}