.info-panel-error {
  color: #ec0000;
  font-size: 15px;
  border-radius: 10px;
}
.info-panel-info {
  color: green;
  font-size: 15px;
  border-radius: 10px;
}
.close_button {
  height: 22px;
  width: 22px;
  margin-left: 10px;
}
.info-panel {
  border: solid 1px lightgray;
  border-radius: 8px;
  padding: 5px;
  margin-top: 10px;
}
