.table-contracts {
}
.table_header {
  background-color: #ec0000;
}
.table_header_item {
  background-color: #ec0000;
  color: white;
  border: none;
  padding: 0px;
  font-size: 15px;
}
.table_header_item_sorted {
  background-color: #ec0000;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 13px;
}
.table_page_button {
  background: transparent;
  border: 1px solid #ec0000;
  color: #ec0000;
  width: 30px;
  height: 30px;
}
.table_page_button_selected {
  background-color: #ec0000;
  border: 1px solid #ec0000;
  color: white;
  width: 30px;
  height: 30px;
  font-weight: bolder;
  font-size: 15px;
}
.table_page_button_selected:focus {
  outline: none !important;
}
.table_page_button:disabled {
  color: gray;
}
.table_row {
  border: none;
  border-bottom: solid 1px #ec0000;
  font-size: 13px;
  height: 20px;
}
.date-filter {
  border-radius: 10px;
}
.table-footer-1 {
  background: #ec0000;
  color: white;
  font-size: 13px;
}

.table-filter-field {
  font-size: 12px;
  padding: 5px;
  border: none;
  border-radius: 4px;
}
.download-select {
  margin-left: 10px;
}
.table-filter-select-field {
  font-size: 12px;
  padding: 6px;
  border: none;
  border-radius: 4px;
}
.contract-list-table-row-second-line {
  font-size: 11px;
  color: dimgrey;
}